.Box {
  border-radius: 10px;
  border: 1px solid black;
  padding: 10px;
  display: block;
}

.QRBox {
  border-radius: 10px;
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
  width: 279px;
  display: block;
  background-color: white;
}